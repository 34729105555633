export const birthdayThemeText = {
  calcTitle: `Купи сертификат
  в подарок себе или близкому`,
  calcSubtitle: '',
  title: `Такое бывает только раз в год`,
  subtitle: `
  Prosto празднует день рождения! 🥳🎂 
  Только 3 дня – самая большая скидка на годовую подписку:
  21.09 -55%
  22.09 -55%
  23.09 -55%`,
  subtitleExtra: `
  🤍 Можно оплатить банковской картой любой страны
  🤍 Сертификат открывает доступ ко всем медитациям и видео приложения Prosto
  🤍 Можно оплатить любое количество сертификатов для себя или близкого человека 
  🤍 Сертификат можно активировать в течение двух лет`,
}