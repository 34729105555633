import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CertificateScreen from '../screens/CertificateScreen/CertificateScreen';
import MainScreen from "../screens/MainScreen/MainScreen";

const MainRoutes: React.FC = () => {

  return (
    <div>
      <Switch>
        <Route
          exact path='/'
          component={MainScreen}
        />
        <Route
          exact path='/certificate'
          component={CertificateScreen}
        />
      </Switch>
    </div>
  );
}

export default MainRoutes;
